.scanner-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90vw;
  max-height: 90vw;
  width: 45vh;
  height: 45vh;
}

.scanner-container > img {
  width: 100%;
  height: auto;
}

.scanner-container > .scanner {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 92px);
  background: #ffffff;
  height: 8px;
  animation: upDown 1s alternate infinite linear;
}

@keyframes upDown {
  0% {
    top: 20%;
  }
  100% {
    top: 80%;
  }
}

.scanner-container > .scanner-info {
  position: absolute;
  top: calc(100% + 32px);
  left: 50%;
  transform: translate(-50%);
  width: calc(100% - 32px);
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

@media screen and (orientation: landscape) {
  .scanner-container > .scanner-info {
    top: calc(100% + 16px);
    width: auto;
    white-space: nowrap;
  }
}
