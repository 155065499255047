.container {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.logos-header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 16px;
  z-index: 1;
  width: 100%;
  padding: 16px 0 0 16px;
  box-sizing: border-box;
  background: linear-gradient(
    180deg,
    rgba(24, 24, 24, 0.6) 0%,
    rgba(38, 38, 38, 0) 100%
  );
}

.logos-header > img:first-child {
  height: 80px;
  width: auto;
}
.logos-header > img:last-child {
  height: 40px;
  width: auto;
}

.buttons {
  position: fixed;
  bottom: 64px;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 16px;
}

.buttons > button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-sizing: border-box;
  background: #2d7fa9;
  opacity: 0.9;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.45);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #ffffff;
  padding: 0;
}

@media screen and (orientation: landscape) {
  .buttons {
    top: 50%;
    right: 32px;
    left: auto;
    transform: translateY(-50%);
    flex-direction: column;
    row-gap: 16px;
  }
}
