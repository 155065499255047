.landing-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 16px;
  overflow-y: auto;
}

.landing-page > * {
  flex-shrink: 0;
  max-width: 360px;
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 64px;
}

.header > .logos {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 16px;
}

.header > .logos > img:first-child {
  height: 80px;
  width: auto;
}
.header > .logos > img:last-child {
  height: 40px;
  width: auto;
}

.header > .language {
  position: relative;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.header > .language > .languages-container {
  position: absolute;
  top: calc(100% + 16px);
  right: 0;
  background: #3e3e3e;
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  width: 200px;
}

.header > .language > .languages-container > .language-selector {
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header > .language > .languages-container > .language-selector > img {
  width: 32px;
}

h1 {
  margin: 0;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: #f0f0f0;
}

button {
  width: 100%;
  height: 48px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  border: 2px solid #2d7fa9;
}

button.start {
  background: rgba(45, 127, 169, 0.3);
  margin-top: 40px;
  margin-bottom: 56px;
}

button.link {
  background: transparent;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 12px;
}

button > span {
  font-size: 24px;
}

h6 {
  margin: 0;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #cfcfcf;
}

h4 {
  margin: 0;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #f0f0f0;
}
