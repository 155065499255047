.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #262626;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  z-index: 9999;
  box-sizing: border-box;
  padding-top: 96px;
  overflow: hidden;
}

.loader {
  position: relative;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  box-sizing: border-box;
  background: linear-gradient(to bottom, #428cb2, #262626 75%);
  animation: spin 1.3s linear infinite;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader > .blocker {
  width: 65px;
  height: 65px;
  background: #262626;
  border-radius: 50%;
}

.loader-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 16px;
}

.loader-container > .loading-label {
  margin: 0;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-info {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 24px;
}

.loader-info > * {
  max-width: 320px;
}

@media screen and (orientation: landscape) {
  .loading {
    padding: 16px;
    padding-left: 152px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 56px;
  }
}
